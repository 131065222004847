import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { Breadcrumb } from "components/breadcrumb"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const Gif1 =
  "https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-6.gif"
const Gif2 =
  "https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-8.gif"
const Gif3 =
  "https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-9.gif"
const Gif4 =
  "https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-11.gif"

const CampaignResult2 = ({ location }) => {
  const title =
    "Twitterキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？！"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterキャンペーン成功の秘訣とは | 4つのテストを徹底比較"
          pagedesc="Twitterキャンペーン成功の秘訣について、キャンペーンバナークリエイティブ・静止画アニメーション画像の違い・景品ギフト券金額・開催期間の観点から解説します。自社アカウントでの実施を検討中の方へ実例を交えて解説。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2.jpg"
          pagepath="/blog/2021-campaign-result-2"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>
              Twitterインスタントウィンキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <span className="blog__time">公開日：2022年1月31日</span>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="パソコンを開きながらメモをしている男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2.jpg"
                className="w-full"
              />
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    PARKLoT公式アカウント キャンペーン結果
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    1. キャンペーンバナーでは、大きい数字を目立たせると効果的
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    2.
                    キャンペーンバナーは静止画よりもアニメーションが断然伸びる！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    3. 新規フォロワーを増やすなら、高額ギフト券の少量配布{" "}
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    4. プレゼントキャンペーンに最適な開催期間は1週間
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    キャンペーン結果から見えたインスタントウィン成功の秘訣
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    自社のアカウントでも試してみたい方に
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    お得に手間なくインスタントウィンをするならPARKLoT
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
              <h2>インスタントウィンを成功させるには？実例を徹底解析！</h2>
              <p>
                <a
                  href="/blog/2021-campaign-result"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterキャンペーンの成功の秘訣は？
                </a>
                で、PARKLoT公式アカウントで開催したキャンペーンのNo.1〜No.10の情報を公開しました。
              </p>
              <p>
                今回の記事ではその続編として、No.11〜No.16の6回分、合計7週間に渡って開催したキャンペーンの結果を、公開したいと思います！
              </p>
              <p>
                No.11〜No.16のキャンペーンでは
                <br />
                ・キャンペーンバナーが結果に及ぼす影響
                <br />
                ・Amazonギフト券の効果的な配り方
                <br />
                ・キャンペーンの適切な期間
                <br />
                に着目して、キャンペーンを開催しました。
              </p>
              <p>
                「どんなキャンペーンが伸びるんだろう？」「景品やキャンペーンバナーはどうすればいいんだろう？」とお悩みの方に、ぜひ読んでいただきたい内容になっています。
              </p>
              <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>
                  Twitterインスタントウィンキャンペーンを開催したいが、企画について悩んでいる。
                </li>
                <li>
                  他社の公式アカウントのキャンペーン結果を知って、自社の企画に活かしたい。
                </li>
              </ul>
              <p>
                本記事では、PARKLoT公式アカウントで開催したキャンペーンについて、以下の情報を公開しています。
              </p>
              <ul className="blog__border-list">
                <p>【調査期間】</p>
                <li>2021年9月27日〜2021年11月14日</li>
                <p>【調査項目】</p>
                <li>キャンペーン開催期間、開催日数</li>
                <li>景品</li>
                <li>フォロワー数</li>
                <li>リツイート数</li>
                <li>フォロワー増加数</li>
              </ul>
            </div>
            <div>
              <h2 id="id1">PARKLoT公式アカウント キャンペーン結果</h2>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="キャペーン結果"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-1.png"
                maxwidth={640}
              />
              <p>
                前回のブログではNo.1〜No.10の結果を公開しました。
                <br />
                今回はNo.11〜No.16の6回分、7週間に渡って開催したキャンペーンの結果を見ていきたいと思います。
              </p>
            </div>
            <div>
              <h3>＜フォロワー数＞</h3>
              <p>
                6回のキャンペーンで、フォロワーが7,488から11,980と
                <strong>4,492フォロワー増加</strong>しました。
              </p>
              <p>
                かかった費用は、
                <br />
                システム利用料：55,000円×2ヶ月=110,000円
                <br />
                景品代：10,000円×6回=60,000円
                <br />
                合計：170,000円
                <br />
              </p>
              <p>
                でしたので、
                <strong>1フォローあたりの費用（CPF）は、約38円</strong>
                になります。
              </p>
              <p>
                Twitter広告の場合は1フォロー100円が相場と言われていますので、
                <strong>半分以下の費用</strong>に抑えることができました！
              </p>
            </div>
            <div>
              <h3>＜リツイート数＞</h3>
              <p>
                前回と同じく、キャンペーン終了日のフォロワー数と、キャンペーンツイートのリツイート数の変化を集計しました。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="1日あたりフォロワー増加数 1日あたりリツイート数"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-2.png"
                maxwidth={640}
              />
              <p>
                前回の記事でお伝えした通り、No.9とNo.10の間に約半年の期間が空いたことにより、それまで順調に伸びていたリツイート数が大きく下がってしまいました。
              </p>
              <p>
                No.11、No.12は引き続きリツイート数が低迷していましたが、
                <strong>No.13から一気に回復しました。</strong>
              </p>
            </div>
            <div>
              <h2 id="id2">
                １．キャンペーンバナーでは、大きい数字を目立たせると効果的
              </h2>
              <p>
                キャンペーンバナーには、キャンペーンの概要、開催期間、景品などを掲載します。
              </p>
              <p>
                Twitterは流し読みされる傾向が高いため、重要な情報を大きく目立つように配置する必要があります。
              </p>
              <p>
                そこで、Twitterキャンペーンで人気なAmazonギフト券を配布する場合に、金額の大きい数字と小さい数字、どちらを目立たせるのが効果的なのか試してみました。
              </p>
            </div>
            <div>
              <h3>＜キャンペーンの情報＞</h3>
              <p>No.11とNo.12のキャンペーンで比較しました。</p>
              <p>
                どちらのキャンペーンも、Amazonギフト券1,000円分を10枚、景品として準備しました。
              </p>
              <p>
                No.11は総額の
                <strong>「10,000円」</strong>を大きく配置、No.12は
                <strong>「1,000円」</strong>を大きく配置しました。
              </p>
              <p>・No.11 大きい数字 10,000円分を強調</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.11大きい数字 10,000円分を強調するキャンペーン静止画バナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-3.png"
                maxwidth={640}
              />
              <p>・No.12 小さい数字 1,000円分を強調</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.12小さい数字 1,000円分を強調するキャンペーン静止画バナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-4.png"
                maxwidth={640}
              />
            </div>
            <div>
              <h3>結果：No.11　大きい数字を強調する方がやや有利！？</h3>
              <p>
                こちらが、二つのキャンペーンのフォロワー増加数とリツイート数をまとめたグラフです。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.11大きい数字を強調するキャンペーンとNo.12小さい数字を強調するキャンペーンのフォロワー増加数とリツイート数を比較するグラフ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-5.png"
                maxwidth={640}
              />
              <p>
                フォロワー増加数においては、No.11の大きい数字を強調した方が良い結果となっています。
                <br />
                <strong>No.11　フォロワー増加数：476</strong>
                <br />
                No.12　フォロワー増加数：372
              </p>
              <p>
                リツイート数は、No.12の小さい数字を強調した方が、少し多いという結果になりました。
                <br />
                No.11　リツイート数：962
                <br />
                <strong>No.12　リツイート数：987</strong>
              </p>
              <p>
                これまでのキャンペーンでは、回数を重ねるごとにフォロワー増加数が増えていました。そのことから考えると、先に開催したNo.11の方がフォロワー増加数が良いということは、
                <strong>
                  大きい数字を強調した方が、ユーザーの「キャンペーンへの参加意欲を刺激する効果がある」
                </strong>
                と言えるかもしれません。
              </p>
            </div>
            <div>
              <h2 id="id3">
                2．キャンペーンバナーは静止画よりもアニメーションが断然伸びる！
              </h2>
              <p>
                PARKLoTで開催されたキャンペーンで、盛り上がったキャンペーンの共通点を探していました。
              </p>
              <p>
                そのときに、アニメーションバナーを使っているキャンペーンの結果が良いことに気づいたので、静止画とアニメーションバナーを準備し、比較してみました。
              </p>
            </div>
            <div>
              <h3>＜キャンペーンの情報＞</h3>
              <p>No.11とNo.13のキャンペーンで比較しました。</p>
              <p>
                No.11は静止画のバナー、No.13はなるべく似たような構図でアニメーションバナーを作成しました。
              </p>
              <p>No.11　静止画のバナー</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.11大きい数字 10,000円分を強調するキャンペーン静止画バナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-3.png"
                maxwidth={640}
              />
              <p>No.13　アニメーションありのバナー</p>
              <img
                alt="No.13大きい数字 10,000円分を強調するキャンペーンアニメーションバナー"
                src={Gif1}
              />
            </div>
            <div>
              <h3>結果：No.13　アニメーションバナーの方が、大きく伸びた！</h3>
              <p>
                こちらが、二つのキャンペーンのフォロワー増加数とリツイート数をまとめたグラフです。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.11静止画バナーのキャンペーンとNo.13アニメーションバナーのキャンペーンのフォロワー増加数とリツイート数を比較するグラフ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-7.png"
              />
              <p>
                フォロワー増加数ですが、No.13のアニメーションバナーが、約2.3倍という結果になりました。
                <br />
                No.11　フォロワー増加数：476
                <br />
                <strong>No.13　フォロワー増加数：1,096</strong>
                <br />
              </p>
              <p>
                リツイート数も、No.13のアニメーションバナーが、約2.6倍と大きく伸ばすことができました。
                <br />
                No.11　リツイート数：962
                <br />
                <strong>No.13　リツイート数：2,535</strong>
              </p>
              <p>
                フォロワー増加数とリツイート数は、回数を重ねるごとに伸びる傾向があるとはいえ、どちらも2倍以上の結果を出しています。
              </p>
              <p>
                アニメーションバナーの方が「目に止まりやすいし、きっと伸びるだろう」と予想はしていましたが、まさかここまで差が出るとは予想していなかったので、キャンペーンの結果が出た時は、驚きました。
              </p>
              <p>
                他の要因が影響している可能性も否定はできませんが、これ以降のPARKLoT公式アカウントのキャンペーンはアニメーションありのバナーを採用するようにしています。
              </p>
            </div>
            <div>
              <h2 id="id4">
                3．新規フォロワーを増やすなら、高額ギフト券の少量配布
              </h2>
              <p>
                Amazonギフト券は参加ユーザーに人気の高い景品の一つです。PARKLoTでも毎回Amazonギフト券を配布していますが、どのような金額と枚数の組み合わせが良いのか、比較してみました。
              </p>
            </div>
            <div>
              <h3>＜キャンペーンの情報＞</h3>
              <p>No.14とNo.15のキャンペーンで比較しました。</p>
              <p>
                どちらのキャンペーンも、総額は10,000円のAmazonギフト券を準備しています。
              </p>
              <p>
                No.14では
                <strong>「5,000円」の高額ギフト券を「2枚」</strong>、No.15では
                <strong>「100円」の少額ギフト券を「100枚」</strong>
                配布しました。
              </p>
              <p>・No.14 高額ギフト券を少量配布</p>
              <img
                alt="No.14高額ギフト券を少量配布するキャンペーンのアニメーションバナー"
                src={Gif2}
              />
              <p>・No.15 少額ギフト券を大量配布</p>
              <img
                alt="No.15少額ギフト券を大量配布するキャンペーンのアニメーションバナー"
                src={Gif3}
              />
            </div>
            <div>
              <h3>
                結果：高額ギフト券を少量配布が、フォロワー増加に効果的大！
              </h3>
              <p>
                こちらが、二つのキャンペーンのフォロワー増加数とリツイート数をまとめたグラフです。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.14高額ギフト券を少量配布するキャンペーンと少額ギフト券を大量配布するキャンペーンのフォロワー増加数とリツイート数を比較するグラフ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-10.png"
              />
              <p>
                フォロワー増加数ですが、No.14の5,000円を2名に配布するキャンペーンが、
                <strong>約3.3倍</strong>という結果になりました。
                <br />
                <strong>No.14　フォロワー増加数：1,057</strong>
                <br />
                No.15　フォロワー増加数：318
              </p>
              <p>
                リツイート数も、No.14が約1.3倍になりました。
                <br />
                <strong>No.14　リツイート数：2,782</strong>
                <br />
                No.15　リツイート数：2,038
              </p>
              <p>
                キャンペーンを開催する前は「100円を100名にプレゼント」の方が当たる可能性が高いので、フォロワー数もリツイート数も伸びるのでは？と予想していましたが、予想とは逆の結果になりました。
              </p>
              <p>あくまでも推測ではありますが</p>
              <ul className="blog__border-list">
                <li>
                  <strong>
                    5,000円が当たる可能性があるならフォローして、リツイートする
                  </strong>
                </li>
                <li>
                  <strong>
                    100円しか当たらないのであれば、わざわざフォローまではしない
                  </strong>
                </li>
                <li>
                  <strong>
                    当選金額が100円でも、すでにフォローしていて、リツイートだけで応募できるならリツイートする
                  </strong>
                </li>
              </ul>
              <p></p>
              <p>というような心理が働いたのではないか？と考えています。</p>
            </div>
            <div>
              <h2 id="id5">4．プレゼントキャンペーンに最適な開催期間は1週間</h2>
              <p>
                PARKLoTでは、TwitterキャンペーンはPDCAサイクルを回して繰り返すことが重要と考えています。
              </p>
              <p>
                そのため、<strong>基本的には1週間で企画をご提案</strong>
                するのですが、通常業務と並行してキャンペーンを開催されている企業のご担当者様から「2週間のキャンペーンを開催したい」とご要望されることもあります。
              </p>
              <p>
                企画を考え、バナーを作成、管理画面に登録するという作業。Twitter抽選ツールを使えば、手動でキャンペーンを開催するより格段に作業効率はアップします。それでも「もし、2週間のキャンペーンでもキャンペーンの効果に影響がないのであれば、2週間で開催できる方がいいかもしれない。」そう考え、1週間と2週間のキャンペーンを比較してみました。
              </p>
            </div>
            <div>
              <h3>＜キャンペーンの情報＞</h3>
              <p>No.13とNo.16のキャンペーンで、比較しました。</p>
              <p>
                他の要因を少しでも少なくするため、バナーの色と期間だけを変更しています。
              </p>
              <p>・No.13　開催期間が1週間のバナー</p>
              <img
                alt="No.13開催期間が1週間のキャンペーンのアニメーションバナー"
                src={Gif1}
              />
              <p>・No.16　開催期間が2週間のバナー</p>
              <img
                alt="No.16開催期間が2週間のキャンペーンのアニメーションバナー"
                src={Gif4}
              />
            </div>
            <div>
              <h3>結果：1週間のキャンペーンを、2回開催するのがベスト！</h3>
              <p>
                こちらが、ふたつのキャンペーンのフォロワー増加数とリツイート数をまとめたグラフです。
              </p>
              <p>
                開催日数が異なるため、No.16は合計数を半分にして集計しています
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.13開催期間が1週間のキャンペーンとNo.16開催期間が2週間のキャンペーンの合計数を半分にした数のフォロワー増加数とリツイート数を比較するグラフ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-12.png"
                maxwidth={640}
              />
              <p>
                これだけ見ると
                <strong>
                  「2週間の方が伸びていないけど、そこまで大きく差はない」
                </strong>
                と思いますよね。
              </p>
              <p>次に見ていただきたいのが、こちらのグラフです。</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.13大きい数字を強調するアニメーションバナーのキャンペーンとNo.16開催期間が2週間のキャンペーンの1週目とNo.16開催期間が2週間のキャンペーンの2週目のフォロワー増加数とリツイート数を比較するグラフ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result-2/2021-campaign-result-2-13.png"
                maxwidth={640}
              />
              <p>
                No.16のキャンペーンについて、1週目、つまり、1日目から7日目の結果と、2週目の8日目から14日目の結果を別々に集計したものです。
              </p>
              <p>
                フォロワー増加数、リツイート数、
                <strong>どちらも2週目の結果が、大きく下がっている</strong>
                ことがわかります。
              </p>
              <p>
                ・フォロワー増加数
                <br />
                No.13　フォロワー増加数：1,096
                <br />
                <strong>No.16（1週目）　フォロワー増加数：1,266</strong>
                <br />
                <strong>No.16（2週目）　フォロワー増加数：383</strong>
              </p>
              <p>
                ・リツイート数
                <br />
                No.13　リツイート数：2,535
                <br />
                <strong>No.16（1週目）　リツイート数：3,764</strong>
                <br />
                <strong>No.16（2週目）　リツイート数：996</strong>
              </p>
              <p>
                私もPARKLoTのキャンペーンの企画・開催・景品の発送を担当しているので、「1週ごとにキャンペーンを開催するのは大変だ」というお気持ちはよく分かります。
              </p>
              <p>
                ただ、ここまで2週目の結果が下がることが分かったので、今後も1週間ごとにキャンペーンを開催していこうと決めました！
              </p>
            </div>
            <div>
              <h2 id="id6">
                キャンペーン結果から見えたインスタントウィン成功の秘訣
              </h2>
              <p>
                最後に、今回のキャンペーン結果から見えてきたことを、まとめます。
              </p>
              <ol className="blog__border-list">
                <li>
                  <strong>
                    キャンペーンバナーは、大きな数字を目立たせ、アニメーションにして、目に止まるようなクリエイティブにすることが重要
                  </strong>
                </li>
                <li>
                  <strong>
                    フォロワーを増やしたいなら、Amazonギフト券の当選金額はアップした方が良い
                  </strong>
                </li>
                <li>
                  <strong>キャンペーンは1週間で繰り返すのが、効果的</strong>
                </li>
              </ol>
              <p>
                キャンペーン企画でお悩みの方は、ぜひこちらを参考にしていただけたらと思います！
              </p>
            </div>
            <div>
              <h2 id="id7">自社のアカウントでも試してみたい方に</h2>
              <p>
                PARKLoTでは
                <strong>
                  「SNSキャンペーンはPDCAサイクルを回して、繰り返し実施することが重要」
                </strong>
                だと考えています。
              </p>
              <p>
                一般的なTwitter抽選ツールは価格が高かったり、キャンペーン1回につきいくら、といった価格設定が多く、PDCAサイクルを回すことが難しいといった課題があります。
              </p>
              <h3 id="id8">
                お得に手間なくインスタントウィンをするならPARKLoT
              </h3>
              <p>
                PARKLoTはそのような課題を解決するため、
                <strong>月額制で何回でも実施</strong>でき、さらに、
                <strong>1ヶ月55,000円〜</strong>
                というリーズナブルな価格で、サービスを提供しています。
              </p>
              <p>
                この記事を読んで、自社の公式アカウントでも、さまざまなキャンペーンを繰り返し実施したいと思われた方は、お気軽にお問い合わせください！
              </p>
              <p>
                初めてPARKLoTをご利用いただく方には、
                <strong>無料で1時間のキャンペーン企画のご相談</strong>
                を承っております。この記事には書ききれなかった、多数のキャンペーンの実績から、貴社に最適なキャンペーンのご提案をさせていただきます。
              </p>
            </div>
            {/* <Link to={`/`}>
              <div className="blog__text-center">
                <StaticImage
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="../../images/brand/blue.png"
                  maxwidth={300}
                ></StaticImage>
              </div>
            </Link> */}
            <p>
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
              </a>
            </p>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="junko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CampaignResult2
